html,
body {
  padding: 0;
  margin: 0;
  font-family: Faktum-Regular, sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: var(--black-30);

}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.wm-container {
  @apply px-24 xl:px-0;

  @screen xl {
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
  }

}
.wm-article__tag{
  h5{
    margin-left: 12px;
  }
}
.wm-btn--primary {
  @apply font-Faktum-Semi-Bold text-white bg-primary-orange rounded-5px;
  &.wm-disabled-btn {
    @apply bg-orange-30;
  }
}
.wm-btn--outline {
  @apply font-Faktum-Semi-Bold text-primary-orange rounded-5px border border-primary-orange;
}