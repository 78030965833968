@import 'https://images.selise.club/ui-assets/fonts/Faktum/faktum.css';
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@function hexopacity($hex){
  @return red($hex), green($hex), blue($hex);
}

:root {
  --primary-orange: #FF771F;
  --orange-50: rgba(#{hexopacity(#FF771F)}, 0.5);
  --orange-30: rgba(#{hexopacity(#FF771F)}, 0.3);
  --orange-20: rgba(#{hexopacity(#FF771F)}, 0.2);
  --old-lace: #FDF4ED;
  --primary-blue: #0088FF;
  --success: #208218;
  --error: #FB0000;
  --white: #FFFFFF;
  --white-warm: #F2F2F2;
  --white-smoke: #F1F1F1;
  --black: #000000;
  --black-80: rgba(#{hexopacity(#000000)}, 0.8);
  --black-30: #202020;
  --light-black: rgba(#{hexopacity(#202020)},0.15);
  --gray-600: #5B5B5B;
  --gray-400: #CCCCCC;
  --gray-300: #FAFAFA;
  --gray-200: #E4E4E4;
  --gray-100: #FBFDFE;

  --orange: #D9651A;
  --purple: #842AFD;
  --purple-50: rgba(#{hexopacity(#842AFD)}, 0.5);
  --purple-20: rgba(#{hexopacity(#842AFD)}, 0.2);
  --blue: #0059FF;
  --blue-50: rgba(#{hexopacity(#0059FF)}, 0.5);
  --blue-20: rgba(#{hexopacity(#0059FF)}, 0.2);
  --green: #01A754;
  --green-50: rgba(#{hexopacity(#01A754)}, 0.5);
  --green-20: rgba(#{hexopacity(#01A754)}, 0.2);
  --pale-turquoise: #74C3B4;
  --orange-light: #FBE9DB;
  --light-blue: #EAF2FA;
  --baby-blue: #96CEFF;
  --pale-jade: #74C3B4;
  --green-pop: #19977F;
}

@layer base {
  h1 {
    @apply text-38px sm:text-44px lg:text-52px leading-46px sm:leading-54px lg:leading-60px;
  }
  h2 {
    @apply lg:text-36px lg:leading-42px sm:text-32px sm:leading-38px text-28px leading-34px;
  }

  h3 {
    @apply text-20px leading-28px sm:text-22px sm:leading-32px lg:text-24px lg:leading-34px;
  }
  h4{
    @apply text-24px leading-34px;
  }

  h5 {
    @apply text-16px leading-24px;
  }
  h6 {
    @apply text-14px leading-22px;
  }

}